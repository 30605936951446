import React from 'react'
import Layout from 'components/layout/layout'
import 'styles/pages/meet-the-crew.scss'
import TeamMember from 'components/team-member/team-member'
import Helment from 'react-helmet'

const MeetTheCrewPage = () => (
  <Layout>
    <Helment>
      <title>Meet the Crew</title>
    </Helment>
    <section className="padding-4">
      <h2 className="margin-y-4 text-center title">Meet the Crew</h2>
      <section className="container-75 team-member-container">
        <TeamMember
          name="Chloe Bootstaylor"
          role="Creative and Marketing"
          fileName="chloe"
          blurb={
            <div className="padding-4">
              <p className="margin-y-2">
                Chloe Bootstaylor is a co-founder and oversees Bantu’s
                creative-marketing and design. A major part of her role is
                establishing brand relationships, scouting new businesses to
                feature and overseeing the Bantu media outlets (this includes
                keeping up and personally engaging with all you beautiful people
                on instagram).
              </p>
              <p className="margin-y-2">
                As a visionary who is always on the go she envisions Bantu as a
                way for people to reconnect with their community. &quot;We are
                all caught up in a routine—buying from the same stores, visiting
                the same coffee shops, checking out what’s on
                trend—unfortunately this typically leaves Black-owned business
                as an afterthought—if a thought at all. Bantu will allow users
                to refocus their energy to invest in their community and make
                Black-owned businesses part of the routine!&quot;
              </p>
              <p className="margin-y-2">
                Chloe was raised in Atlanta and when she is not working on Bantu
                she is practicing law in Las Vegas!
              </p>
            </div>
          }
        />
        <TeamMember
          name="Marcus Jones"
          role="Data Analyst"
          fileName="marcus"
          blurb={
            <div className="padding-4">
              <p className="margin-y-2">
                Marcus Jones is a co-creator and Bantu’s Data Analyst. Marcus’s
                duties include overseeing business & user information, along
                with new member recruitment.
              </p>
              <p className="margin-y-2">
                &quot;My goal is to bridge informational gaps to help bring to
                life an experience that gets consumers and business-owners{' '}
                <b>excited</b> about Black-owned business!&quot;
              </p>
            </div>
          }
        />
        <TeamMember
          name="Jeremey Key"
          role="Tech and Quality"
          fileName="jeremey"
          blurb={
            <div className="padding-4">
              <p className="margin-y-2">
                Jeremey Key is a co-founder, co-designer and chief web engineer
                for Bantu. Jeremey’s responsibilities include developing and
                maintaining Bantu’s digital interface.
              </p>
              <p className="margin-y-2">
                With the goal of creating a user friendly platform, Jeremey
                welcomes feedback on how Bantu can make improvements to better
                serve users. Jeremey was born and raised in Memphis, TN, and is
                currently residing in Las Vegas, NV and working as a software
                engineer.
              </p>
              <p className="margin-y-2">
                &quot;Black excellence is everywhere; and, I want to use my
                skill set to help promote its abundance through Bantu.&quot;
              </p>
            </div>
          }
        />
        <TeamMember
          name="Christopher Cochran"
          role="Design and Tech"
          fileName="chris"
          blurb={
            <div className="padding-4">
              <p className="margin-y-2">
                Christopher Cochran is a co-creator and chief design engineer
                for Bantu. With a love for art and design, Christopher channels
                those into Bantu’s interface by ensuring Bantu’s blueprint comes
                to life in digital design.
              </p>
              <p className="margin-y-2">
                &quot;I aim for my work to speak for the black excellence we
                stand for. Black is beautiful, and Bantu should share that
                beauty.&quot;
              </p>
              <p className="margin-y-2">
                In addition to design, Christohper also co-manages the Bantu
                website, with a goal of creating an accessible place to share
                the best of black business.
              </p>
              <p className="margin-y-2">
                Christopher resides in the NYC area and works as a digital
                product engineer.
              </p>
            </div>
          }
        />
      </section>
    </section>
  </Layout>
)

export default MeetTheCrewPage
