import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { motion, AnimatePresence } from "framer-motion";
import 'styles/components/modal.scss';

// TODO: create accessible tab trap so focus doesn't leave the modal
const Modal = ({ children }: ModalProps) => {
    const elRef = useRef(null);

    if(!elRef.current) {
        const div = document.createElement('div');
        elRef.current = div;
    }

    useEffect(() => {
        const modalRoot = document.getElementById('modal');
        modalRoot.appendChild(elRef.current);

        return () => modalRoot.removeChild(elRef.current);
    }, []);

    const modalElement = (
        <AnimatePresence>
            <motion.div
                key="modal-backdrop"
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                className="modal-backdrop"
            >
                <motion.aside
                    key="modal-content"
                    animate={{ scale: 1, translateY: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    initial={{ translateY: 500, opacity: 0, scale: 0 }}
                    exit={{ translateY: 500, opacity: 0, scale: 0 }} // TODO: figure out why this doesn't animate translation out
                    className="modal-content padding-2"
                >
                    {children}
                </motion.aside>
            </motion.div>
        </AnimatePresence>
    )
    return createPortal(modalElement, elRef.current);
};

interface ModalProps {
    children: JSX.Element
}

export { Modal };