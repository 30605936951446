import React, { useState } from 'react'
import { Modal } from 'components/modal/modal'
import { motion } from 'framer-motion'
import { useStaticQuery, graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import CloseIcon from 'images/svgs/icon-close-circle.svg'
import 'styles/components/team-member.scss'

export const fixedImage = graphql`
  fragment fixedImage on File {
    childImageSharp {
      fixed(width: 250, height: 350) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`
export const fixedImageSmall = graphql`
  fragment fixedImageSmall on File {
    childImageSharp {
      fixed(width: 50, height: 50, cropFocus: ATTENTION) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`

const TeamMember = ({ name, fileName, role, blurb }: TeamMemberProps) => {
  const [showModal, toggleModal] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      chloe: file(relativePath: { regex: "/chloe.jpeg/" }) {
        ...fixedImage
      }
      jeremey: file(relativePath: { regex: "/jeremey.jpeg/" }) {
        ...fixedImage
      }
      chris: file(relativePath: { regex: "/chris.jpeg/" }) {
        ...fixedImage
      }
      marcus: file(relativePath: { regex: "/marcus.jpeg/" }) {
        ...fixedImage
      }
      chloeSmall: file(relativePath: { regex: "/chloe-small.jpg/" }) {
        ...fixedImageSmall
      }
      jeremeySmall: file(relativePath: { regex: "/jeremey-small.jpeg/" }) {
        ...fixedImageSmall
      }
      chrisSmall: file(relativePath: { regex: "/chris-small.jpeg/" }) {
        ...fixedImageSmall
      }
      marcusSmall: file(relativePath: { regex: "/marcus-small.jpeg/" }) {
        ...fixedImageSmall
      }
    }
  `)

  const fixedImage = data[fileName].childImageSharp.fixed
  const fixedImageSmall = data[`${fileName}Small`].childImageSharp.fixed

  return (
    <motion.section
      className="team-member margin-y-4"
      initial={{
        rotate: 0,
      }}
      whileHover={{
        scale: 1.05,
        rotate: [3, -3, 3, -3, 3, -3, 0],
      }}
      transition={{
        type: 'spring',
        stiffness: 100,
        damping: 200,
      }}
    >
      <button onClick={() => toggleModal(!showModal)}>
        <Img
          className="team-member__photo br-small shadow-1"
          fixed={fixedImage}
          alt={`Photo of ${name}`}
        />
      </button>
      <button
        className="team-member__info text-center padding-4 br-small shadow-2"
        onClick={() => toggleModal(!showModal)}
      >
        <h3>{name}</h3>
        <p>{role}</p>
      </button>
      <button
        className="view-bio__btn br-small padding-2"
        onClick={() => toggleModal(!showModal)}
      >
        View bio
      </button>

      {showModal && (
        <Modal>
          <TeamMemberContentContainer
            imagePath={fixedImageSmall}
            name={name}
            role={role}
            closeModal={() => toggleModal(false)}
          >
            {blurb}
          </TeamMemberContentContainer>
        </Modal>
      )}
    </motion.section>
  )
}

interface ContentHeaderProps {
  children: JSX.Element
  imagePath: FixedObject
  name: string
  role: string
  closeModal: () => void
}
function TeamMemberContentContainer({
  children,
  imagePath,
  role,
  name,
  closeModal,
}: ContentHeaderProps): JSX.Element {
  return (
    <article>
      <button className="modal-close-btn" onClick={closeModal}>
        <CloseIcon />
      </button>
      <header className="team-member__modal-header">
        <Img
          className="team-member__modal-photo margin-x-3"
          fixed={imagePath}
          alt={`Photo of ${name}`}
        />
        <div>
          <h2>{name}</h2>
          <h3>{role}</h3>
        </div>
      </header>
      {children}
    </article>
  )
}

interface TeamMemberProps {
  name: string
  fileName: string
  role: string
  blurb: JSX.Element
}

export default TeamMember
